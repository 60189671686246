import React from "react";
import { Navigation, Pagination, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { NavLink } from "react-router-dom";
import HomeProductList from "../component/products/HomeProductList";

export default function Home() {
  return (
    <>
      <section className="hero-banner">
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          navigation={true}
          pagination={{ clickable: true }}
          modules={[Navigation, Pagination, A11y]}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          <SwiperSlide>
            <div className="container">
              <div className="row gy-4 gx-lg-5 justify-content-between">
                <div className="col-lg-6 d-flex justify-content-center align-items-center fade-left">
                  <img
                    src="images/banner-img-1.png"
                    alt=""
                    className="img-fluid hero-banner-img"
                  />
                </div>
                <div className="col-lg-6">
                  <div className="hero-banner-content">
                    <span className="small-heading mb-3">Best Quality</span>
                    <h2 className="heading-1 mb-3">
                      Durable Openwell <span>Pump</span> Solutions
                    </h2>
                    <p className="large-text-1 mb-4">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Ut elit tellus, luctus nec ullamcorper mattis, pulvinar
                      dapibus leo.
                    </p>
                    <NavLink to="/products" className="btn btn-black mt-2">
                      <span>Shop Now</span>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="container">
              <div className="row gy-4 gx-lg-5 justify-content-between">
                <div className="col-lg-6 d-flex justify-content-center align-items-center fade-left">
                  <img
                    src="images/banner-img-1.png"
                    alt=""
                    className="img-fluid hero-banner-img"
                  />
                </div>
                <div className="col-lg-6">
                  <div className="hero-banner-content">
                    <span className="small-heading mb-3">Best Quality</span>
                    <h2 className="heading-1 mb-3">
                      Durable Openwell <span>Pump</span> Solutions
                    </h2>
                    <p className="large-text-1 mb-4">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Ut elit tellus, luctus nec ullamcorper mattis, pulvinar
                      dapibus leo.
                    </p>
                    <NavLink to="/products" className="btn btn-black mt-2">
                      <span>Shop Now</span>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>
      <section className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-xxl-9">
              <span className="small-heading">About Company</span>
              <h1 className="mb-2 mt-1">
                Setting a tradition of excellence since 2015.
              </h1>
              <p className="large-text-2 mb-4">
                Welcome to PotterPumps, a brand that has set a benchmark in the
                industry since 2015. From our beginnings with Openwell Pumps, we
                have continually expanded our expertise to include Submersible
                Pumps, Self-Priming Pumps, and their components.
              </p>
              <NavLink to="/about-us" className="btn btn-black">
                About Us
              </NavLink>
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <span className="small-heading">Our Products</span>
              <h2 className="heading-2 mb-0 mt-1">Our Products</h2>
            </div>
            <div className="col-lg-6 d-none d-lg-flex justify-content-end align-items-center">
              <NavLink to="/products" className="btn btn-black">
                View All
              </NavLink>
            </div>
            <div className="col-12 mt-5">
              <HomeProductList />
            </div>
            <div className="col-12 d-flex justify-content-center d-lg-none mt-4 pt-2">
              <NavLink to="/products" className="btn btn-black">
                View All
              </NavLink>
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding">
        <div className="container">
          <div className="justify-content-center pb-5">
            <h1>Why choose us?</h1>
          </div>
          <div className="row align-items-center flex-column-reverse flex-lg-row">
            <div className="col-lg-9 col-xxl-8">
              <h2 className="mb-2 mt-1">ISO 9001:2015</h2>
              <p className="large-text-2 mb-0">
                we are proud to be ISO 9001 certified, a globally recognized
                standard for quality management. This certification highlights
                our commitment to improving performance, and delivering
                high-quality products. It reflects our dedication to excellence.
              </p>
            </div>
            <div className="col-lg-3 col-xxl-4 d-flex align-items-center justify-content-center justify-content-lg-end mb-4 mb-lg-0">
              <img
                src="images/iso-9001-2015-logo.png"
                alt=""
                className="img-fluid"
                width="220"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding pt-0">
        <div className="container">
          <div className="row align-items-center flex-column-reverse flex-lg-row-reverse">
            <div className="col-lg-9 col-xxl-8">
              <h2 className="mb-2 mt-1">Best Quality</h2>
              <p className="large-text-2 mb-0">
                At Potter Pumps, quality is at the heart of what we do. Using
                energy-saving pure copper wires and high-quality brass materials
                for bushings, we ensure every product meets rigorous standards
                for efficiency and durability.
              </p>
            </div>
            <div className="col-lg-3 col-xxl-4 d-flex align-items-center justify-content-center justify-content-lg-start mb-4 mb-lg-0">
              <img
                src="images/best-quality-logo.png"
                alt=""
                className="img-fluid"
                width="220"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
